<template>
	<div>
		<pui-welcome-panel />
	</div>
</template>

<script>
export default {
	mounted() {
		console.log('MOUNTED');
		this.preparaEntorno();
	},
	methods: {
		preparaEntorno() {
			this.$store.state.entorno = JSON.parse(window.localStorage.getItem('distributionAppData'));
			if (this.$store.state.entorno.logo) {
				var logoElement = document.getElementsByClassName('puiwellcomepanel__logo-img')[0];
				logoElement.setAttribute(
					'style',
					"background:url('data:image/png;base64, " +
						this.$store.state.entorno.logo +
						"') no-repeat center !important; background-size: cover; opacity: 1;"
				);
			}

			var pwp = document.getElementsByClassName('layout pui-welcome-panel')[0];
			if (pwp) {
				var headerText = pwp.getElementsByTagName('h1')[0];
				if (this.$store.state.entorno.autoridadportuaria && headerText) {
					//console.log(this.$store.state.global.autoridadportuaria);
					headerText.innerHTML = this.$t('puiwellcomepanel.header') + this.$store.state.entorno.autoridadportuaria;
				}
			}
			//console.log(this.$store.state.global.minilogo);
			var minilogoElement = document.getElementsByClassName('puimenu-leftBar__logo-img')[0];
			if (this.$store.state.entorno.minilogo && minilogoElement) {
				minilogoElement.setAttribute(
					'style',
					"background:url('data:image/png;base64, " + this.$store.state.entorno.minilogo + "') no-repeat center !important; opacity: 1;"
				);
			}
		}
	}
};
</script>
